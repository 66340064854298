<template>
  <v-container>
    <div id="login" style="height: 100%">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5" lg="4">
          <v-card elevation="12" style="margin-top: calc(50vh - 268px)">
            <v-progress-linear
              :active="!loaded"
              indeterminate
              absolute
              top
            ></v-progress-linear>
            <v-card-text style="padding: 40px 40px 40px 40px">
              <div class="text-center mb-4">
                <img
                  src="../assets/logo.png"
                  alt="Bryggeriforeningen"
                  style="width: 240px; height: auto"
                />
              </div>
              <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
                <v-text-field
                  label="Email"
                  placeholder="Enter email address"
                  name="email"
                  type="text"
                  required
                  :rules="[requiredRule, emailRule]"
                  v-model="form.email"
                ></v-text-field>

                <v-text-field
                  id="password"
                  label="Adgangskode"
                  placeholder="Enter password"
                  name="password"
                  type="password"
                  required
                  :rules="[requiredRule]"
                  v-model="form.password"
                ></v-text-field>

                <router-link to="/forgotpassword">
                  <v-btn text small class="primary--text mb-6 px-0" width="100%"
                    >Glemt adgangskode?</v-btn
                  >
                </router-link>

                <v-btn type="submit" class="primary" large min-width="200" block
                  >Log ind</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, email } from "../utils/FormRules.js";

export default {
  name: "Login",
  components: {},
  data: () => ({
    valid: false,
    form: {
      email: "",
      password: "",
    },
    requiredRule: required,
    emailRule: email,
  }),
  watch: {
    token: function (val) {
      if (val) {
        this.$router.replace("/");
      }
    },
  },
  methods: {
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.login({
          email: this.form.email,
          password: this.form.password,
        });
      }
    },
    ...mapActions(["login"]),
  },
  computed: {
    ...mapState(["loaded", "user", "token", "systemMessage"]),
  },
};
</script>
