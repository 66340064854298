export const required = (v) => !!v || "Feltet er påkrævet";

export const email = (v) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v
  ) || "Gyldig email-adresse påkrævet";

export const number = (v) =>
  !isNaN(parseFloat(v.replace(",", "."))) || "Skal være et tal";

export const lengthEight = (v) =>
  v.length > 7 || "Skal være mindst 8 karakterer";

export const hasUpper = (v) =>
  /[A-Z]/.test(v) || "Skal have mindst et stort bogstav";

export const hasLower = (v) =>
  /[a-z]/.test(v) || "Skal have mindst et lille bogstav";

export const hasNumber = (v) => /\d/.test(v) || "Skal have mindst et tal";

export const hasSpecialCharacter = (v) =>
  /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v) ||
  "Skal have mindst et symbol";
